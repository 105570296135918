import {Notify} from 'quasar';
import {messageFromError} from 'src/utils';

export function SET_SUBJECT({commit, dispatch}, item) {

  commit('RESET_COUNTER');
  commit('RESET_CORRECT');

  commit('SET_SUBJECT', item);
  dispatch('SET_RANDOM_QUESTION');
}

export function SET_RANDOM_QUESTION({state, commit, getters, dispatch}) {

  // Find not passed questions.

  const {questions: total} = getters;
  const {passedQuestions: passed} = state;

  let questions = total.filter(
    t => !passed.find(p => t.id === p.id),
  );

  // If not found, clear passed questions.

  if (!questions.length) {

    commit('RESET_PASSED_QUESTIONS');
    questions = total;
  }

  if (!questions.length)
    return Notify.create({
      message: 'Нет вопросов для выбора.',
      color: 'negative',
    });

  const random = Math.floor(
    Math.random() * questions.length,
  );

  dispatch(
    'SET_QUESTION',
    questions[random],
  );
}

export function SET_QUESTION({state, commit}, item) {

  commit('RESET_DURATION');
  commit('RESET_CORRECT_ANSWER');
  commit('RESET_SELECTED_ANSWER');

  commit('SET_COUNTER', state.counter + 1);
  commit('SET_QUESTION', item);
}

export async function VALIDATE({
  state,
  commit,
  getters,
  rootState,
  rootGetters,
}) {

  if (!state.selectedAnswer)
    return Notify.create({
      message: 'Выберите ответ',
      color: 'negative',
    });

  if (state.loading)
    return;

  // Loading state.

  commit('SET_LOADING', true);

  // Request.

  const body = {
    userId: rootGetters['session/userId'],
    guestId: rootState.session.guestId,
    duration: state.duration,
    answerId: state.selectedAnswer.id,
  };

  let response;

  try {

    response = await this.$api.post(
      'quizAnswers/validate',
      body,
    );

    const {data} = response;

    // Get Correct Answer.

    if (data.correctAnswer)
      commit(
        'SET_CORRECT_ANSWER',
        data.correctAnswer,
      );

  // Error.

  } catch (error) {

    // Notify.

    const message = messageFromError(
      error,
      'Неизвестная ошибка',
    );

    if (/duration/i.test(message)) {

      Notify.create({
        message: 'Не торопитесь :)',
        color: 'warning',
      });

    } else {

      Notify.create({
        message,
        color: 'negative',
      });
    }
  }

  // Save passed.

  commit('SET_PASSED_QUESTIONS', [
    ...state.passedQuestions,
    state.question,
  ]);

  // Iterate correct.

  if (getters.validated === true)
    commit(
      'SET_CORRECT',
      state.correct + 1,
    );

  // Loading state.

  commit('RESET_LOADING');

  // Return Status.

  return !!response;
}

export async function REMIND({
  state,
  commit,
  rootState,
  rootGetters,
}, {
  silent = false,
} = {}) {

  if (
    !state.question ||
    state.loading
  ) return;

  // Loading state.

  commit('SET_LOADING', true);

  // Request.

  const body = {
    userId: rootGetters['session/userId'],
    guestId: rootState.session.guestId,
    questionId: state.question.id,
  };

  try {

    const {data} = await this.$api.post(
      'quizResponses/remindAnswer',
      body,
    );

    // Get Correct Answer.

    if (data.userAnswer)
      commit(
        'SET_SELECTED_ANSWER',
        data.userAnswer,
      );

    if (data.correctAnswer)
      commit(
        'SET_CORRECT_ANSWER',
        data.correctAnswer,
      );

    if (data.correctAnswer)
      commit(
        'SET_DURATION',
        data.duration,
      );

  // Error.

  } catch (error) {

    // Notify.

    const message = messageFromError(
      error,
      'Неизвестная ошибка',
    );

    if (!silent)
      Notify.create({
        message,
        color: 'negative',
      });
  }

  // Loading state.

  commit('RESET_LOADING');

  // Return State.

  return state;
}
