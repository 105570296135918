
export default function () {

  return {
    startColor: 'rgba(255, 255, 255, 0.09)',
    startTextColor: 'white',
    scrolledColor: 'rgba(217, 217, 217, 0.09)',
    scrolledTextColor: '#3E4243',
    showMenu: true,
    showShadow: true,
  };
}
