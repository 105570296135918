import initialState from './state';

export function SET_RUNNING_INTRO(state, value) {

  state.runningIntro = value || null;
}

export function RESET_RUNNING_INTRO(state) {

  state.runningIntro = initialState().runningIntro;
}

export function SET_RUNNING_PLAYER(state, value) {

  state.runningPlayer = value || null;
}

export function RESET_RUNNING_PLAYER(state) {

  state.runningPlayer = initialState().runningPlayer;
}

export function SET_TIMER(state, value) {

  state.timer = value || null;
}

export function RESET_TIMER(state) {

  state.timer = initialState().timer;
}
