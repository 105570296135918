
export function questions(state) {

  const items = state.subject
    && state.subject.quizQuestions
    || [];

  return items.filter(
    item => !item.featured,
  );
}

export function answers(state) {

  return state.question
    && state.question.quizAnswers
    || [];
}

export function questionTitle(state) {

  return state.question
    && state.question.title.ru
    || 'без названия';
}

export function subjectPath(state) {

  const slugByQuestion = state.question
    && state.question.quizSubject
    && state.question.quizSubject.slug
    || '';

  const slugBySubject = state.subject
    && state.subject.slug
    || '';

  return `/quiz-subject/${slugByQuestion || slugBySubject}`;
}

export function subjectTitle(state) {

  const titleByQuestion = state.question
    && state.question.quizSubject
    && state.question.quizSubject.title
    && state.question.quizSubject.title.ru;

  const titleBySubject = state.subject
    && state.subject.title
    && state.subject.title.ru;

  return titleByQuestion
    || titleBySubject
    || 'без темы';
}

export function validated(state) {

  if (
    !state.correctAnswer ||
    !state.selectedAnswer
  ) return null;

  const {
    correctAnswer: correct,
    selectedAnswer: selected,
  } = state;

  return selected.id === correct.id;
}

export function done(state) {

  return state.limit <= state.counter;
}
