import {Notify} from 'quasar';
import {messageFromError} from '@src/utils';

export async function VALIDATE({state, commit}) {

  // Loading state.

  commit('SET_LOADING', true);

  // Request.

  try {

    await this.$api.post(
      'users/canRegister',
      {email: state.email.trim()},
    );

  // Error.

  } catch (error) {

    // Notify.

    const message = messageFromError(
      error,
      'Неизвестная ошибка',
    );

    Notify.create({
      message,
      color: 'negative',
    });

    // Loading state.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Update State.

  commit('SET_VALIDATED', true);

  // Notify.

  Notify.create({
    message: 'Set password',
    color: 'info',
  });

  // Loading State.

  commit('RESET_LOADING');

  // Success.

  return true;
}

export async function REGISTER({commit, state, dispatch}) {

  // Loading state.

  commit('SET_LOADING', true);

  // Create Credentials.

  const credentials = {
    email: state.email.trim(),
    name: state.name.trim(),
    surname: state.surname.trim(),
    password: state.password,
    companyName: state.companyName.trim(),
  };

  // Request.

  try {

    await this.$api.post(
      'users/register',
      credentials,
    );

  // Error.

  } catch (error) {

    // Notify.

    const message = messageFromError(
      error,
      'Неизвестная ошибка',
    );

    Notify.create({
      message,
      color: 'negative',
    });

    // Loading state.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Update State.

  commit('SET_REGISTERED', true);
  commit('RESET_VALIDATED');

  // Loading State.

  commit('RESET_LOADING');

  // Success.

  return true;
}
