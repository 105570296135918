
/**
 * Get file Url.
 *
 * @param data
 * @param size
 * @param placeholder
 * @return {string}
 */
export function getFileUrl(data, size = null, placeholder = '') {

  // File name.

  let name = '';

  // Array must be converted to an object.

  data = Array.isArray(data) && 0 in data
    ? data[0]
    : data;

  // Data must be an object.
  data = Object.isObject(data)
    ? data
    : {};

  // Resolve baseUrl.

  const baseUrl = data
    && data.baseUrl
    || 'https://site-content.collectiveweb.com' // Old storage still used.
    || process.env.CDN_BASE_URL;

  // Size provided.

  if (size && data.thumbs) {

    // Thumbnail 1900.

    if (size === 1900) {

      if (size.toString() in data.thumbs) {

        name = data.thumbs[size.toString()];

      } else size = 1200;
    }

    // Thumbnail 1200.

    if (size === 1200) {

      if (size.toString() in data.thumbs) {

        name = data.thumbs[size.toString()];

      } else size = 800;
    }

    // Thumbnail 800.

    if (size === 800) {

      if (size.toString() in data.thumbs) {

        name = data.thumbs[size.toString()];

      } else size = 400;
    }

    // Thumbnail 400.

    if (size === 400) {

      if (size.toString() in data.thumbs) {

        name = data.thumbs[size.toString()];

      } else size = 200;
    }

    // Thumbnail 200.

    if (size === 200) {

      if (size.toString() in data.thumbs)
        name = data.thumbs[size.toString()];
    }
  }

  name = name || data.name;

  // Old storage compatibility.

  if (!data.baseUrl)
    return name && data.container
      ? `${baseUrl}/${name}`
      : placeholder;

  return name && data.container
    ? `${baseUrl}/${data.container}/${name}`
    : placeholder;
}

/**
 * Blob to Data Url.
 *
 * @param blob
 * @return {Promise<*>}
 */
export async function blobToDataUrl(blob) {

  return new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = event => resolve(event.target.result);
    reader.onerror = error => reject(error);

    reader.readAsDataURL(blob);
  });
}

/**
 * Simple file type.
 *
 * @param file
 * @return {string|undefined}
 */
export function simpleFileType(file) {

  if (!file)
    return undefined;

  const mime = file.mime || file.type || '';

  if (!mime)
    return undefined;

  if (
    mime.indexOf('image/') === 0 &&
    mime.indexOf('svg') === -1
  ) return 'image';

  if (mime.indexOf('video/') === 0)
    return 'video';

  return 'document';
}
