import initialState from './state';

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_DURATION(state, value) {

  state.duration = value || 0;
}

export function RESET_DURATION(state) {

  state.duration = initialState().duration;
}

export function SET_LIMIT(state, value) {

  state.limit = value || 0;
}

export function RESET_LIMIT(state) {

  state.limit = initialState().limit;
}

export function SET_COUNTER(state, value) {

  state.counter = value || 0;
}

export function RESET_COUNTER(state) {

  state.counter = initialState().counter;
}

export function SET_CORRECT(state, value) {

  state.correct = value || 0;
}

export function RESET_CORRECT(state) {

  state.correct = initialState().correct;
}

export function SET_SUBJECT(state, value) {

  state.subject = value || null;
}

export function RESET_SUBJECT(state) {

  state.subject = initialState().subject;
}

export function SET_QUESTION(state, value) {

  state.question = value || null;
}

export function RESET_QUESTION(state) {

  state.question = initialState().question;
}

export function SET_CORRECT_ANSWER(state, value) {

  state.correctAnswer = value || null;
}

export function RESET_CORRECT_ANSWER(state) {

  state.correctAnswer = initialState().correctAnswer;
}

export function SET_SELECTED_ANSWER(state, value) {

  state.selectedAnswer = value || null;
}

export function RESET_SELECTED_ANSWER(state) {

  state.selectedAnswer = initialState().selectedAnswer;
}

export function SET_PASSED_QUESTIONS(state, value) {

  state.passedQuestions = value || null;
}

export function RESET_PASSED_QUESTIONS(state) {

  state.passedQuestions = initialState().passedQuestions;
}
