
/**
 * Has role.
 *
 * @param name
 * @param user
 * @return {boolean}
 */
export function hasRole(name, user) {

  if (!name)
    return false;

  const names = Array
    .toArray(name);

  const isAny = names
    .indexOf('*') !== -1;

  if (isAny)
    return true;

  if (
    !user ||
    !user.role
  ) return false;

  return names.indexOf(
    user.role.name,
  ) !== -1;
}

export function getUserName(user, empty = '') {

  if (!user)
    return empty;

  return `${user.name} ${user.surname}`.trim() || empty;
}
