
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    // limit: 20,
    // total: 0,
    orderBy: 'id',
    // orderDesc: false,
    // searchBy: ['name'],
    // searchQuery: '',
    // where: {},
    // loading: false,
    include: [
      'quizSubject',
      'quizAnswers',
    ],
    // fields: [],
  };
}
