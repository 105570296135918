<template>

  <!-- Universal Link -->
  <router-link
    :to="to"
    :target="target"
    class="universal-link"
    :class="{'no-pointer': !to}">

    <a
      :href="to"
      :target="target"
      slot-scope="scope"
      @click="v => !/https?:/.test(to) && scope.navigate(v)">

      <slot
        v-bind="scope"/>

    </a>

  </router-link>

</template>

<script>

  export default {
    name: 'universal-link',
    props: [
      'to',
      'target',
    ],
  };

</script>

<style lang="scss">

  .universal-link {
    color: inherit;

    &.no-pointer {
      cursor: default !important;
    }
  }

</style>
