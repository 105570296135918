import sortBy from 'lodash/sortBy';
import {fromChatMessage} from '@src/utils';

const limit = parseInt(
  process.env.MESSAGES_LRU_LIMIT,
  10,
);

export async function SEND_CHAT_MESSAGE({state, rootGetters}, payload) {

  const original = state.items.find(
    item => item.id === payload.id,
  );

  let message;

  try {

    message = await fromChatMessage(
      rootGetters['session/userId'],
      payload,
      original,
    );

  } catch (error) {

    console.warn(error);
  }

  if (message)
    return this.$socket.emit(
      'sendMessage',
      message,
    );
}

export function REMOVE_CHAT_MESSAGE({state}, message) {

  return this.$socket.emit(
    'removeMessage',
    message,
  );
}

// eslint-disable-next-line camelcase
export function SOCKET_getMessage({state, commit}, message) {

  let updated = false;

  // Update Existed.

  let items = state.items.map(item => {

    if (item.id !== message.id)
      return item;

    updated = true;
    return message;
  });

  // Add New.

  if (!updated)
    items.push(message);

  // Sort.

  items = sortBy(
    items,
    ['createdAt'],
  );

  // Commit.

  commit(
    'SET_ITEMS',
    items.slice(-limit),
  );
}

// eslint-disable-next-line camelcase
export function SOCKET_getMessages({state, commit, dispatch}, messages) {

  messages.forEach(message => dispatch(
    'SOCKET_getMessage',
    message,
  ));
}

// eslint-disable-next-line camelcase
export function SOCKET_removeMessage({state, commit}, message) {

  commit('SET_ITEMS', state.items.filter(
    item => item.id !== message.id,
  ));
}
