import Vue from 'vue';
import {date} from 'quasar';
import inject from '@src/services/boot-injector';

// Default Mask.

date.mask = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

/**
 * Format UTC date.
 *
 * @param value
 * @param args
 * @return {string}
 */
date.utcFormat = function (value, ...args) {

  const moment = Vue.prototype.$moment;
  return moment(value).utc().format(...args);
};

/**
 * Format local date.
 *
 * @param value
 * @param args
 * @return {string}
 */
date.localFormat = function (value, ...args) {

  const moment = Vue.prototype.$moment;
  return moment(value).local().format(...args);
};

/**
 * Build UTC Date.
 *
 * @param options
 * @return {string}
 */
date.utcBuild = function (options = {}) {

  const value = date.buildDate(options);
  const moment = Vue.prototype.$moment;
  return moment(value).format(date.mask);
};

/**
 * Human Date
 *
 * @param first
 * @param second
 * @param year
 * @param time
 * @param timeOnly
 * @return {string}
 */
date.humanDate = function ({
  first,
  second = null,
  year = false,
  time = false,
  timeOnly = false,
}) {

  if (!first && !second)
    return '';

  const isSameDate = date.isSameDate(
    first,
    second,
  );

  const isSameDay = date.isSameDate(
    first,
    second,
    'day',
  );

  let singlePattern = 'D MMMM';
  let firstPattern = 'D MMM';
  let secondPattern = 'D MMM';

  if (isSameDay)
    secondPattern = '';

  if (year) {

    singlePattern += ' YYYY';
    secondPattern += ' YYYY';
  }

  if (time) {

    singlePattern += ' HH:mm';
    firstPattern += ' HH:mm';
    secondPattern += ' HH:mm';
  }

  if (timeOnly) {

    singlePattern = 'HH:mm';
    firstPattern = 'HH:mm';
    secondPattern = 'HH:mm';
  }

  if (!second || isSameDate || !secondPattern)
    return date.utcFormat(
      first,
      singlePattern,
    );

  const firstFormat = date.utcFormat(
    first,
    firstPattern,
  );

  const secondFormat = date.utcFormat(
    second,
    secondPattern,
  );

  return `${firstFormat} - ${secondFormat}`;
};

/**
 * Now Is Between.
 *
 * @param startAt
 * @param endAt
 * @param offset
 * @return {boolean}
 */
date.nowBetween = (
  startAt,
  endAt,
  {offset = 0} = {},
) => {

  if (!startAt)
    return false;

  const moment = Vue.prototype.$moment;
  const now = moment();
  const startUtc = moment(startAt).add(offset, 'hours');
  const endUtc = moment(endAt).add(offset, 'hours');

  const started = startUtc.diff(now) <= 0;
  const ended = endUtc && endUtc.diff(now) <= 0;

  return started && !ended;
};

// Inject.

export default inject(() => ({
  date,
}));
