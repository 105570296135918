
export function toCssSize(value) {

  if (typeof value === 'number')
    return `${value}px`;

  return typeof value !== 'string'
    ? null
    : value;
}
