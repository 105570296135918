import {onError} from '@src/store';

export async function FETCH_ITEM_BY_SLUG({commit, dispatch}, {slug}) {

  const filter = {
    where: {slug},
  };

  const items = await dispatch(
    'FETCH_ITEMS',
    {filter},
  );

  const item = items.length
    ? items[0]
    : null;

  commit('SET_ITEM', item);

  return item;
}

export async function ADD_VIEW({state}) {

  if (!state.item)
    return onError({
      message: 'Обновить просмотры не удалось.',
    });

  try {

    await this.$api.get(
      `vendors/${state.item.id}/addView`,
    );

  } catch (error) {

    onError({
      error,
      action: 'ADD_VIEW',
    });
  }
}

export async function ADD_VIEW_OF({state}, {field, id}) {

  if (!state.item)
    return onError({
      message: 'Обновить просмотры не удалось.',
    });

  try {

    await this.$api.get(
      `vendors/${state.item.id}/addViewOf/${field}/${id}`,
    );

  } catch (error) {

    onError({
      error,
      action: 'ADD_VIEW',
    });
  }
}
