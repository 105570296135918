import set from 'lodash/set';
import clone from 'lodash/clone';
import merge from 'lodash/merge';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

/**
 * Clone
 *
 * @param  {object} object
 * @return {object}
 */
Object.clone = clone;

/**
 * Deep clone
 *
 * @param  {object} object
 * @return {object}
 */
Object.cloneDeep = cloneDeep;

/**
 * Merge
 *
 * @param  {object} args
 * @return {object}
 */
Object.merge = function (...args) {

  return assign({}, ...args);
};

/**
 * Deep merge
 *
 * @param  {object} args
 * @return {object}
 */
Object.mergeDeep = function (...args) {

  return merge({}, ...args);
};

/**
 * Is a non-null and non-array object.
 *
 * @param  {object}  object
 * @return {boolean}
 */
Object.isObject = function (object) {

  return typeof object === 'object'
    && !Array.isArray(object)
    && !!object;
};

/**
 * To object.
 *
 * @param value
 * @return {null|*}
 */
Object.toObject = function (value) {

  // First from array.

  value = Array.isArray(value) && value.length
    ? value[0]
    : value;

  // Clone if an object.

  return Object.isObject(value)
    ? Object.cloneDeep(value)
    : null;
};

/**
 * Set value by path.
 *
 * @param obj
 * @param path
 * @param value
 * @return {Object}
 */
Object.setIn = function (obj, path, value) {

  return set(
    cloneDeep(obj),
    path,
    value,
  );
};
