
export async function UPDATE_RUNNING({state, commit, getters, rootGetters}) {

  // Update State.

  await commit(
    'SET_RUNNING_INTRO',
    getters.getRunningIntros()[0],
  );

  await commit(
    'SET_RUNNING_PLAYER',
    getters.getRunningPlayers()[0],
  );

  // Navigate to Player.

  const routeName = this
    .$router
    .currentRoute
    .name;

  const query = {
    uid: rootGetters['session/userId'],
  };

  if (state.runningPlayer) {

    if (routeName !== 'filled-player')
      this.$router.push({
        name: 'filled-player',
        query,
      });

  // Navigate to Intro.

  } else if (state.runningIntro) {

    if (routeName !== 'public-expo')
      this.$router.push({
        name: 'public-expo',
        query,
      });

  // Back to Home.

  } else if (
    routeName === 'filled-player' ||
    routeName === 'public-expo'
  ) {

    this.$router.push({
      name: 'public-home',
    });
  }

  return state;
}

export async function START_LISTENING({state, commit, dispatch}) {

  if (process.env.SERVER)
    return;

  if (state.timer)
    return;

  // Start Timer.

  const timer = setInterval(
    () => dispatch('UPDATE_RUNNING'),
    3000,
  );

  commit('SET_TIMER', timer);

  // Fetch Program.

  await dispatch('FETCH_ITEMS');

  // Update Running.

  await dispatch('UPDATE_RUNNING');
}

export async function STOP_LISTENING({state, commit, dispatch}) {

  if (!state.timer)
    return;

  clearInterval(
    state.timer,
  );

  commit('RESET');
}
