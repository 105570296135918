
export default ({router, store}) => {

  // Clean state.

  router.beforeEach((to, from, next) => {

    const toName = (to && to.name) || '';
    const fromName = (from && from.name) || '';

    if (toName !== fromName && fromName) {

        // store.commit('page/RESET');
        // store.commit('quiz/RESET');
        // store.commit('search/RESET');
        // store.commit('global/RESET');
        store.commit('header/RESET');
        store.commit('vlNews/RESET');
        // store.commit('session/RESET');
        store.commit('vlPages/RESET');
        store.commit('vlEvents/RESET');
        store.commit('register/RESET');
        store.commit('vlVendors/RESET');
        // store.commit('messenger/RESET');
        store.commit('vlFaqPages/RESET');
        store.commit('vlRequests/RESET');
        // store.commit('vlPrograms/RESET');
        store.commit('vlNewsPages/RESET');
        store.commit('vlFeedbacks/RESET');
        store.commit('vlAcademies/RESET');
        store.commit('vlMainPages/RESET');
        store.commit('vlQuizPages/RESET');
        store.commit('vlExpoPages/RESET');
        store.commit('vlSearchPages/RESET');
        store.commit('vlEventsPages/RESET');
        // store.commit('vlNavigations/RESET');
        store.commit('vlApplications/RESET');
        store.commit('vlQuizSubjects/RESET');
        store.commit('vlQuizQuestions/RESET');
        store.commit('vlSubscriptions/RESET');

        // Events Collection.
        store.commit('vlPastEvents/RESET');
        store.commit('vlActiveEvents/RESET');
        store.commit('vlAnnounceEvents/RESET');
    }

    next();
  });
};
