import {Quasar} from 'quasar';

export default async ({ssrContext}) => {

  /* eslint-disable comma-dangle */
  await import(
    /* webpackInclude: /(ru|en-us)\.js$/ */
    'quasar/lang/ru'
  ).then(lang => {
    Quasar.lang.set(
      lang.default,
      ssrContext,
    );
  });
};
