import 'plyr/dist/plyr.css';
import VuePlyr from 'vue-plyr/dist/vue-plyr.ssr.js';

export default ({Vue}) => {

  Vue.use(VuePlyr, {
    plyr: {
      volume: 0.3,
    },
  });
};
