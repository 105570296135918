import {onError} from '@src/store';

export async function ADD_VIEW({state}) {

  if (!state.item)
    return onError({
      message: 'Обновить просмотры не удалось.',
    });

  try {

    await this.$api.get(
      'academies/single/addView',
    );

  } catch (error) {

    onError({
      error,
      action: 'ADD_VIEW',
    });
  }
}
