import Vue from 'vue';
import inject from '@src/services/boot-injector';

function scrollTo(top = 0, behavior = 'smooth') {

  window.scrollTo({
    top,
    behavior,
  });
}

export default inject(() => ({
  scrollTo,
}));
