import initialState from './state';

export function SET_USER(state, value) {

  state.user = value || null;
}

export function RESET_USER(state) {

  state.user = initialState().user;
}

export function SET_LOGIN(state, value) {

  state.login = value || '';
}

export function RESET_LOGIN(state) {

  state.login = initialState().login;
}

export function SET_PASSWORD(state, value) {

  state.password = value || '';
}

export function RESET_PASSWORD(state) {

  state.password = initialState().password;
}

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_GUEST_ID(state, value) {

  state.guestId = value || '';
}

export function RESET_GUEST_ID(state) {

  state.guestId = initialState().guestId;
}
