import Vue from 'vue';
import 'moment/locale/ru';
import moment from 'moment';

moment.locale('ru');
Vue.prototype.$moment = moment;

moment.prototype.round = function (unit) {

  // noinspection FallThroughInSwitchStatementJS

  switch (unit) {

    case 'year':
    case 'years':
      this.month(0);

    case 'month':
    case 'months':
      this.day(0);

    case 'day':
    case 'days':
      this.hour(0);

    case 'hour':
    case 'hours':
      this.minute(0);

    case 'minute':
    case 'minutes':
      this.second(0);

    case 'second':
    case 'seconds':
      this.millisecond(0);
  }

  return this;
};
