import Vue from 'vue';
import inject from '@src/services/boot-injector';

const qDate = {};

qDate.toUtc = function (value) {

  const regExp = /\d{4}\/\d{2}\/\d{2}/;

  return regExp.test(value)
    ? `${value.replace(/\//g, '-')}T00:00:00.000Z`
    : value;
};

qDate.diff = function (first, second, unit = 'seconds') {

  const moment = Vue.prototype.$moment;
  first = moment(qDate.toUtc(first)).round(unit);
  second = moment(qDate.toUtc(second)).round(unit);
  return second.diff(first, unit);
};

qDate.gt = function (first, second, unit = 'days') {

  return qDate.diff(first, second, unit) < 0;
};

qDate.gte = function (first, second, unit = 'days') {

  return qDate.diff(first, second, unit) <= 0;
};

qDate.lt = function (first, second, unit = 'days') {

  return qDate.diff(first, second, unit) > 0;
};

qDate.lte = function (first, second, unit = 'days') {

  return qDate.diff(first, second, unit) >= 0;
};

export default inject(() => ({
  qDate,
}));
