import initialState from './state';

export function SET_REFERENCE_ID(state, value) {

  state.referenceId = value || null;
}

export function RESET_REFERENCE_ID(state) {

  state.referenceId = initialState().referenceId;
}

export function SET_REFERENCE_TYPE(state, value) {

  state.referenceType = value || null;
}

export function RESET_REFERENCE_TYPE(state) {

  state.referenceType = initialState().referenceType;
}
