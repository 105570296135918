<template>

  <div
    id="q-app">

    <router-view/>

  </div>

</template>

<script>

  export default {
    name: 'App',
    beforeMount() {

      // Icons fix.

      this.$q.iconSet.field.error = 'fa lni-warning';
      this.$q.iconSet.field.clear = 'fa lni-close';
      this.$q.iconSet.tabs.left = 'fa lni-chevron-left';
      this.$q.iconSet.tabs.right = 'fa lni-chevron-right';
    },
  };

</script>
