import {Cookies} from 'quasar';
import inject from '@src/services/boot-injector';

export default inject(
  ({ssrContext}) => {

    const instance = process.env.SERVER
      ? Cookies.parseSSR(ssrContext)
      : Cookies;

    return {
      cookies: instance,
    };
  },
);
