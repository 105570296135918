
/**
 * Message from error.
 *
 * @param error
 * @param fallback
 * @return {string}
 */
export function messageFromError(
  error,
  fallback = 'Неизвестная ошибка',
) {

  console.log(error);

  return (error
    && error.response
    && error.response.data
    && error.response.data.error
    && error.response.data.error.message
  ) || fallback;
}

/**
 * Code from error.
 *
 * @param error
 * @param fallback
 * @return {string}
 */
export function codeFromError(
  error,
  fallback = '',
) {

  return (error
    && error.response
    && error.response.data
    && error.response.data.error
    && error.response.data.error.code
  ) || fallback;
}

/**
 * Status code from error.
 *
 * @param error
 * @param fallback
 * @return {string}
 */
export function statusCodeFromError(
  error,
  fallback = 0,
) {

  return (error
    && error.response
    && error.response.data
    && error.response.data.error
    && error.response.data.error.statusCode
  ) || fallback;
}
