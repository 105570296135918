
export function getById(state) {

  return (id) => state.items.find(
    item => item.id === id,
  );
}

export function getByIds(state) {

  return (ids) => state.items.filter(
    item => ids && !!ids.find(
      id => item.id === id,
    ),
  );
}
