import {toCssSize} from '../utils';
import {getFileUrl} from '../utils';

export default ({Vue}) => {

  const filters = {
    toCssSize,
    getFileUrl,
  };

  Object.keys(filters).forEach(name => {

    Vue.filter(name, filters[name]);
  });
};
