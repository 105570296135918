import Vue from 'vue';
import Vuex from 'vuex';
import {Notify} from 'quasar';
import {createApi} from '@src/boot/api';

import createPageModule from './page';
import createQuizModule from './quiz';
import createSearchModule from './search';
import createVlNewsModule from './vlNews';
import createGlobalModule from './global';
import createHeaderModule from './header';
import createSessionModule from './session';
import createVlPagesModule from './vlPages';
import createVlEventsModule from './vlEvents';
import createRegisterModule from './register';
import createFaqPagesModule from './vlFaqPages';
import createVlVendorsModule from './vlVendors';
import createMessengerModule from './messenger';
import createVlRequestModule from './vlRequests';
import createVlProgramsModule from './vlPrograms';
import createVlNewsPagesModule from './vlNewsPages';
import createVlFeedbacksModule from './vlFeedbacks';
import createVlAcademiesModule from './vlAcademies';
import createVlMainPagesModule from './vlMainPages';
import createVlQuizPagesModule from './vlQuizPages';
import createVlExpoPagesModule from './vlExpoPages';
import createVlSearchPagesModule from './vlSearchPages';
import createVlEventsPagesModule from './vlEventsPages';
import createVlNavigationsModule from './vlNavigations';
import createVlApplicationsModule from './vlApplications';
import createVlQuizSubjectsModule from './vlQuizSubjects';
import createVlQuizQuestionsModule from './vlQuizQuestions';
import createVlSubscriptionsModule from './vlSubscriptions';
import {messageFromError} from '@src/utils';

// Events Collection.
import createVlPastEventsModule from './vlPastEvents';
import createVlActiveEventsModule from './vlActiveEvents';
import createVlAnnounceEventsModule from './vlAnnounceEvents';

Vue.use(Vuex);

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export const onSuccess = ({action}) => {

  /*

  if (
    action === 'FETCH_ITEM' ||
    action === 'FETCH_ITEMS'
  ) return;

  Notify.create({
    color: 'positive',
    message: 'Операция выполнена.',
  });

  */
};

export const onError = ({error, message}) => {

  Notify.create({
    color: 'negative',
    message: message
      || error.message
      || 'Неизвестная ошибка',
  });
};

export default function ({ssrContext}) {

  const client = createApi({ssrContext}).api;

  const options = {
    client,
    onError,
    onSuccess,
  };

  const store = new Vuex.Store({
    strict: process.env.DEV,
    modules: {
      page: createPageModule(options),
      quiz: createQuizModule(options),
      search: createSearchModule(options),
      global: createGlobalModule(options),
      header: createHeaderModule(options),
      vlNews: createVlNewsModule(options),
      session: createSessionModule(options),
      vlPages: createVlPagesModule(options),
      vlEvents: createVlEventsModule(options),
      register: createRegisterModule(options),
      vlVendors: createVlVendorsModule(options),
      messenger: createMessengerModule(options),
      vlFaqPages: createFaqPagesModule(options),
      vlRequests: createVlRequestModule(options),
      vlPrograms: createVlProgramsModule(options),
      vlNewsPages: createVlNewsPagesModule(options),
      vlFeedbacks: createVlFeedbacksModule(options),
      vlAcademies: createVlAcademiesModule(options),
      vlMainPages: createVlMainPagesModule(options),
      vlQuizPages: createVlQuizPagesModule(options),
      vlExpoPages: createVlExpoPagesModule(options),
      vlSearchPages: createVlSearchPagesModule(options),
      vlEventsPages: createVlEventsPagesModule(options),
      vlNavigations: createVlNavigationsModule(options),
      vlApplications: createVlApplicationsModule(options),
      vlQuizSubjects: createVlQuizSubjectsModule(options),
      vlQuizQuestions: createVlQuizQuestionsModule(options),
      vlSubscriptions: createVlSubscriptionsModule(options),

      // Events Collection.
      vlPastEvents: createVlPastEventsModule(options),
      vlActiveEvents: createVlActiveEventsModule(options),
      vlAnnounceEvents: createVlAnnounceEventsModule(options),
    },
  });

  store.subscribeAction(({type}) => {

    if (
      type.includes('FETCH_PAGE') &&
      typeof window !== 'undefined'
    ) window.scrollTo(0, 0);
  });

  return store;
}
