
export function fabOffset(index = 0) {

  const margin = 30;
  const next = 45 + margin;

  return [
    margin + (next * index),
    margin,
  ];
}
