
export function targets() {

  return [
    {value: '', label: 'Текущее окно'},
    {value: '_blank', label: 'Новое окно'},
  ];
}

export function menu(state, getters, rootState, rootGetters) {

  const items = state.item
    && state.item.items
    || [];

  const isLoggedIn = rootGetters[
    'session/isLoggedIn'
  ];

  return items.filter(
    v => v.authorized
      ? isLoggedIn
      : true,
  );
}
