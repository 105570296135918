
export function loading(state, getters, rootState) {

  return rootState.vlNews.loading
    || rootState.vlEvents.loading;
}

export function totalItems(state, getters, rootState) {

  return rootState.vlEvents.items.length
    + rootState.vlNews.items.length;
}
