import {onError} from '@src/store';

export async function SUBMIT({state, commit}, data) {

  if (!data)
    return onError({
      message: 'No data found.',
    });

  commit('SET_LOADING', true);

  data.referenceId = state.referenceId;
  data.referenceType = state.referenceType;

  let response;

  try {

    response = await this.$api.post(
      'applications/submit',
      data,
    );

  } catch (error) {

    onError({
      error,
      action: 'SUBMIT',
    });

  } finally {

    commit('RESET_LOADING');
  }

  return response
    ? response.data
    : null;
}
