
export default function () {

  return {
    loading: false,
    duration: 0,
    limit: 8,
    counter: 0,
    correct: 0,
    subject: null,
    question: null,
    correctAnswer: null,
    selectedAnswer: null,
    passedQuestions: [],
  };
}
