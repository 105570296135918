import {date} from 'quasar';
import {Notify} from 'quasar';
import {ObjectID} from 'bson';
import {getFileUrl, simpleFileType} from '@src/utils/file';
import {getUserName} from '@src/utils/user';
import messageModel from '@src/models/message';
import {messageFromError} from '@src/utils/api';
import {createApi} from '@src/boot/api';

export function toChatUser(user) {

  return {
    id: user.id,
    name: getUserName(user),
    imageUrl: getFileUrl(user.avatar, 200),
  };
}

export function toChatMessage(senderId, message) {

  return {
    id: message.id,
    type: message.type,
    author: message.senderId === senderId ? 'me' : message.senderId,
    isEdited: message.updatedAt,
    createdAt: message.createdAt,
    data: {
      code: message.code,
      text: message.text,
      meta: date.localFormat(message.createdAt, 'HH:mm')
        + ' | ' + getUserName(message.sender),
      file: {
        name: message.file && message.originalName || '',
        url: getFileUrl(message.file),
        data: message.file,
      },
    },
  };
}

export async function fromChatMessage(
  senderId,
  message,
  original = null,
) {

  const file = message &&
    message.data &&
    message.data.file;

  let fileData;

  if (file) {

    const isImage = file.type !== 'image/gif' &&
      file.type !== 'image/svg+xml' &&
      simpleFileType(file) === 'image';

    if (!isImage) {

      Notify.create({
        message: 'Images only',
        color: 'negative',
      });

      throw Error('Неверный тип файла.');
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    const client = createApi().api;

    const {data} = await client.post(
      process.env.API_UPLOAD_PATH,
      formData,
    ).catch(error => {

      Notify.create({
        message: messageFromError(error, 'Upload failed.'),
        color: 'negative',
      });

      return {data: undefined};
    });

    fileData = data;
  }

  return Object.cloneDeep({
    ...messageModel,
    id: message.id || new ObjectID().toString(),
    type: message.type,
    targetId: message.targetId,
    senderId: message.author === 'me' ? senderId : message.author,
    createdAt: message.createdAt || new Date().toISOString(),
    ...original,
    text: message.data.text,
    code: message.data.code,
    file: fileData,
    updatedAt: original ? new Date().toISOString() : null,
  });
}
