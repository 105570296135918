import {Notify} from 'quasar';
import validator from 'validator';
import {messageFromError} from '@src/utils';
import {codeFromError, hasRole} from '@src/utils';

const allowedRoles = process
  .env['ALLOWED_ROLES']
  .split(',');

export async function SIGN_IN({state, commit}) {

  // Loading state.

  commit('SET_LOADING', true);

  // Create Credentials.

  const isEmail = validator.isEmail(
    state.login,
  );

  const key = isEmail
    ? 'email'
    : 'username';

  const credentials = {
    password: state.password,
    [key]: state.login.trim().toLowerCase(),
  };

  // Request.

  let user;
  let token;
  let tokenTtl;

  try {

    const {data: login} = await this.$api.post(
      'users/login',
      credentials,
    );

    const {data: me} = await this.$api.get(
      `users/me?include=role&access_token=${login.id}`,
    );

    user = me;
    token = login.id;
    tokenTtl = login.ttl;

  // Error.

  } catch (error) {

    // Notify.

    const message = codeFromError(error) !== 'LOGIN_FAILED'
      ? messageFromError(error, 'Unknown error')
      : 'Incorrect login or password';

    Notify.create({
      message,
      color: 'negative',
    });

    // Loading state.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Email Checking.

  if (
    isEmail &&
    !user.emailVerified
  ) {

    // Notify.

    Notify.create({
      message: 'E-mail не подтвержден',
      color: 'negative',
    });

    // Loading state.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Role Checking.

  const roleAllowed = hasRole(
    allowedRoles,
    user,
  );

  if (!roleAllowed) {

    // Notify.

    Notify.create({
      message: 'Недопустимая роль',
      color: 'negative',
    });

    // Loading state.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Set Cookie.

  this.$cookies.set('accessToken', token, {
    expires: Math.ceil(tokenTtl / 86400),
    path: '/',
  });

  // Update State.

  commit('SET_USER', user);
  commit('RESET_LOGIN');
  commit('RESET_PASSWORD');

  // Notify.

  /*

  Notify.create({
    message: 'Авторизация успешно выполнена',
    color: 'positive',
  });

  */

  // Navigate.

  this.$router.push({
    name: 'public-home',
  });

  // Loading State.

  commit('RESET_LOADING');

  // Success.

  return true;
}

export async function RESOLVE_USER({commit}) {

  // Fallback.

  const fallback = () => {

    // Remove Cookie.

    this.$cookies.remove(
      'accessToken',
      {path: '/'},
    );

    // Reset State.

    commit('RESET_USER');

    // Loading State.

    commit('RESET_LOADING');

    // Failed.

    return false;
  };

  // Loading State.

  commit('SET_LOADING', true);

  // Request.

  let user;

  try {

    const {data} = await this.$api.get(
      'users/me?include=role',
    );

    user = data;

  // Error.

  } catch (error) {

    // Failed.

    return fallback();
  }

  // Role Checking.

  const roleAllowed = hasRole(
    allowedRoles,
    user,
  );

  if (!roleAllowed) {

    // Failed.

    return fallback();
  }

  // Update State.

  commit('SET_USER', user);

  // Loading State.

  commit('RESET_LOADING');

  // Success.

  return true;
}

export async function SIGN_OUT({commit}) {

  // Loading State.

  commit('SET_LOADING', true);

  // Request.

  try {

    await this.$api.post(
      'users/logout',
    );

  // Error.

  } catch (error) {

    // Notify.

    Notify.create({
      message: messageFromError(error, 'Ошибка деавторизации'),
      color: 'negative',
    });

    // Loading State.

    commit('RESET_LOADING');

    // Failed.

    return false;
  }

  // Remove Cookie.

  this.$cookies.remove(
    'accessToken',
    {path: '/'},
  );

  // Reset State.

  commit('RESET_USER');

  // Notify.

  /*

  Notify.create({
    message: 'Выход успешно выполнен',
    color: 'positive',
  });

  */

  // Navigate.

  this.$router.push({
    name: 'dialog-login',
  });

  // Socket.IO

  this.$socket.disconnect();

  // Loading state.

  commit('RESET_LOADING');

  // Success.

  return true;
}

// eslint-disable-next-line camelcase
export async function SOCKET_connect({commit}) {

  const token = this.$cookies.get(
    'accessToken',
  );

  this.$socket.emit(
    'authentication',
    {id: token},
  );
}

export async function ADD_SCORE({state, commit, getters}, {
  name,
  vendorId = '',
  referenceId = '',
  referenceType = '',
}) {

  if (!name)
    throw Error('No activity name.');

  const options = {
    name,
    vendorId,
    referenceId,
    referenceType,
    userId: state.user.id,
  };

  let response;

  try {

    response = await this.$api.post(
      'userActivities/add',
      options,
    );

  } catch (error) {

    console.log(error);
  }

  const score = response
    && response.data
    && response.data.totalScore
    || 0;

  commit('SET_USER', {
    ...state.user,
    score,
  });
}

export async function SELECT_VENDOR(
  {state, commit, getters, rootState},
  vendorId,
) {

  if (!vendorId)
    throw Error('No vendor to select.');

  // Loading state.

  commit('SET_LOADING', true);

  // Send request.

  let response;

  try {

    response = await this.$api.patch(
      `users/${state.user.id}`,
      {vendorId},
    );

  // Error.

  } catch (error) {

    console.log(error);

    // Notify.

    Notify.create({
      message: messageFromError(error, 'Join failed'),
      color: 'negative',
    });
  }

  // Update State.

  if (response) {

    commit('SET_USER', {
      ...state.user,
      vendorId,
    });

    Notify.create({
      message: 'You are on the team!',
      color: 'positive',
    });

    const vendor = rootState
      .vlVendors
      .item;

    if (vendor) {

      vendor.users = vendor.users || [];
      vendor.users = [state.user, ...vendor.users];

      commit(
        'vlVendors/SET_ITEM',
        vendor,
      );
    }
  }

  // Loading state.

  commit('RESET_LOADING');
}
