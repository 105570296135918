
export async function SEND_FEEDBACK(
  {state, commit, dispatch, rootGetters},
  {text, file},
) {

  const userId = rootGetters['session/userId'];

  await dispatch('CREATE_TEMP_ITEM', {
    userId,
    text,
    file,
  });

  await dispatch('PUT_TEMP_ITEM');

  return state;
}
