import {toChatUser} from '@src/utils';
import {toChatMessage} from '@src/utils';

export function chatUsersOf(state) {

  return (targetId) => {

    const messages = state.items.filter(
      item => item.targetId === targetId,
    );

    return messages
      .map(v => v.sender)
      .filter(v => v)
      .map(toChatUser);
  };
}

export function chatMessagesOf(state, getters, rootState, rootGetters) {

  return (targetId) => {

    const items = state.items.filter(
      item => item.targetId === targetId,
    );

    return items.map(message => toChatMessage(
      rootGetters['session/userId'],
      message,
    ));
  };
}

export function isModeratorOf(state, getters, rootState, rootGetters) {

  return (targetId) => {

    // Is Admin.

    const roleName = rootGetters[
      'session/roleName'
    ];

    if (roleName === 'admin')
      return true;

    // My Channel.

    const channelId = rootGetters[
      'session/channelId'
    ];

    if (targetId === channelId)
      return true;

    // Is Moderator
    // without Channel.

    const roles = [
      'VendorRepresentative',
      'moderator-speaker',
    ];

    const hasRole = roles.indexOf(
      roleName,
    ) !== -1;

    return hasRole && !channelId;
  };
}
