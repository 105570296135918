
export async function FETCH_ITEM_BY_SLUG({commit, dispatch}, {slug}) {

  const filter = {
    where: {
      slug,
      hidden: {neq: true},
    },
  };

  const items = await dispatch(
    'FETCH_ITEMS',
    {filter},
  );

  const item = items.length
    ? items[0]
    : null;

  commit('SET_ITEM', item);

  return item;
}
