import initialState from './state';

export function RESET(state, value) {

  Object.assign(
    state,
    initialState(),
  );
}

export function SET_START_COLOR(state, value) {

  state.startColor = value || '';
}

export function RESET_START_COLOR(state) {

  state.startColor = initialState().startColor;
}

export function SET_START_TEXT_COLOR(state, value) {

  state.startTextColor = value || '';
}

export function RESET_START_TEXT_COLOR(state) {

  state.startTextColor = initialState().startTextColor;
}

export function SET_SCROLLED_COLOR(state, value) {

  state.scrolledColor = value || '';
}

export function RESET_SCROLLED_COLOR(state) {

  state.scrolledColor = initialState().scrolledColor;
}

export function SET_SCROLLED_TEXT_COLOR(state, value) {

  state.scrolledTextColor = value || '';
}

export function RESET_SCROLLED_TEXT_COLOR(state) {

  state.scrolledTextColor = initialState().scrolledTextColor;
}

export function SET_SHOW_MENU(state, value) {

  state.showMenu = !!value;
}

export function RESET_SHOW_MENU(state) {

  state.showMenu = initialState().showMenu;
}

export function SET_SHOW_SHADOW(state, value) {

  state.showShadow = !!value;
}

export function RESET_SHOW_SHADOW(state) {

  state.showShadow = initialState().showShadow;
}
