
export function isLoggedIn(state) {

  return !!state.user;
}

export function userId(state) {

  return state.user
    && state.user.id
    || '';
}

export function roleId(state) {

  return state.user
    && state.user.roleId
    || '';
}

export function guideId(state) {

  return state.user
    && state.user.guideId
    || '';
}

export function channelId(state) {

  return state.user
    && state.user.channelId
    || '';
}

export function companyId(state) {

  return state.user
    && state.user.companyId
    || '';
}

export function roleName(state) {

  return state.user
    && state.user.role
    && state.user.role.name
    || '';
}

export function getStudio(state, getters) {

  return studios => {

    const channel = getters
      .getChannel(studios);

    if (!channel)
      return;

    return studios.find(
      item => item.channels.find(
        v => v.id === channel.id,
      ),
    );
  };
}

export function getChannel(state, getters) {

  return studios => {

    // Not Authenticated.

    if (!getters.isLoggedIn) {

      studios = Object.cloneDeep(studios);

      studios.forEach(studio => {

        studio.channels = studio.channels
          .filter(v => !v.authenticated);
      });
    }

    // My Channel.

    studios = (studios || []).filter(
      item => item.channels && item.channels.length,
    );

    let channels = studios.reduce(
      (total, item) => [...total, ...item.channels],
      [],
    );

    const myChannel = channels.find(
      item => item.id === getters.channelId,
    );

    if (myChannel)
      return myChannel;

    // Get Studio of Company or Common.

    const companyStudio = studios.find(
      item => item.companyId === getters.companyId,
    );

    const commonStudio = studios.find(
      item => !item.companyId,
    );

    const studio = companyStudio
      || commonStudio;

    channels = studio
      && studio.channels
      || [];

    // Role Channel.

    const roleChannel = channels.find(
      item => item.roleIds.indexOf(
        getters.roleId,
      ) !== -1,
    );

    if (roleChannel)
      return roleChannel;

    // Common Channel.

    return channels.find(
      item => !item.roleIds.length,
    );
  };
}
