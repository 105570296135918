
const routes = [
  {
    path: '/',
    component: () => import('layouts/public-layout.vue'),
    meta: {
      requiredRoles: ['$authenticated'],
    },
    children: [
      {
        path: '',
        name: 'public-home',
        component: () => import('pages/public/home-page.vue'),
        meta: {
          title: 'Главная',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'expo',
        name: 'public-expo',
        component: () => import('pages/public/expo-page.vue'),
        meta: {
          title: 'Экспо',
        },
      },
      {
        path: 'academy',
        name: 'public-academy',
        component: () => import('pages/public/academy-page.vue'),
        meta: {
          title: 'Академия',
        },
      },
      {
        path: 'search',
        name: 'public-search',
        component: () => import('pages/public/search-page.vue'),
        meta: {
          title: 'Поиск',
          requiredRoles: ['$reset'],
        },
        props: route => ({
          query: route.query.q,
        }),
      },
      {
        path: 'faq',
        name: 'public-faq',
        component: () => import('pages/public/faq-page.vue'),
        meta: {
          title: 'FAQ',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'vendor/:slug',
        name: 'public-vendor',
        component: () => import('pages/public/vendor-page.vue'),
        meta: {
          title: 'Вендор',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'page/:slug',
        name: 'public-page',
        component: () => import('pages/public/page-page.vue'),
        meta: {
          title: 'Страница',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'question/:slug',
        name: 'public-quiz-question',
        component: () => import('pages/public/quiz-question-page.vue'),
        meta: {
          title: 'Вопрос',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'quiz',
        name: 'public-quiz',
        component: () => import('pages/public/quiz-page.vue'),
        meta: {
          title: 'Викторина',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'quiz-subject/:slug',
        name: 'public-quiz-subject',
        component: () => import('pages/public/quiz-subject-page.vue'),
        meta: {
          title: 'Тематика',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'news',
        name: 'public-news',
        component: () => import('pages/public/news-page.vue'),
        meta: {
          title: 'Новости',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'news-item/:slug',
        name: 'public-news-item',
        component: () => import('pages/public/news-item-page.vue'),
        meta: {
          title: 'Новость',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'events',
        name: 'public-events',
        component: () => import('pages/public/events-page.vue'),
        meta: {
          title: 'Мероприятия',
          requiredRoles: ['$reset'],
        },
      },
      {
        path: 'event/:slug',
        name: 'public-event',
        component: () => import('pages/public/event-page.vue'),
        meta: {
          title: 'Мероприятие',
          requiredRoles: ['$reset'],
        },
      },
    ],
  },
  {
    path: '/filled',
    component: () => import('layouts/filled-layout.vue'),
    children: [
      {
        path: 'player',
        name: 'filled-player',
        component: () => import('pages/filled/player-page.vue'),
        meta: {
          title: 'Мультиплеер',
        },
      },
    ],
  },
  {
    path: '/dialog',
    component: () => import('layouts/dialog-layout.vue'),
    children: [
      {
        path: 'login',
        name: 'dialog-login',
        component: () => import('pages/dialog/login-page.vue'),
        meta: {
          title: 'Авторизация',
        },
      },
      {
        path: 'register',
        name: 'dialog-register',
        component: () => import('pages/dialog/register-page.vue'),
        meta: {
          title: 'Регистрация',
        },
      },
      {
        path: 'logout',
        name: 'dialog-logout',
        component: () => import('pages/dialog/logout-page.vue'),
        meta: {
          title: 'Выход',
        },
      },
      {
        path: 'verify',
        name: 'dialog-verify',
        component: () => import('pages/dialog/verify-page.vue'),
        meta: {
          title: 'Подтверждение',
        },
      },
    ],
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('pages/error-page.vue'),
    props: true,
  },
];

// Always leave this as last one.
if (process.env.MODE !== 'ssr') {

  routes.push({
    path: '*',
    component: () => import('pages/error-page.vue'),
  });
}

export default routes;
