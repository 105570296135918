import initialState from './state';

export function RESET(state) {

  Object.assign(
    state,
    initialState(),
  );
}

export function SET_EMAIL(state, value) {

  state.email = value || '';
}

export function RESET_EMAIL(state) {

  state.email = initialState().email;
}

export function SET_NAME(state, value) {

  state.name = value || '';
}

export function RESET_NAME(state) {

  state.name = initialState().name;
}

export function SET_SURNAME(state, value) {

  state.surname = value || '';
}

export function RESET_SURNAME(state) {

  state.surname = initialState().surname;
}

export function SET_PASSWORD(state, value) {

  state.password = value || '';
}

export function RESET_PASSWORD(state) {

  state.password = initialState().password;
}

export function SET_COMPANY_NAME(state, value) {

  state.companyName = value || '';
}

export function RESET_COMPANY_NAME(state) {

  state.companyName = initialState().companyName;
}

export function SET_LOADING(state, value) {

  state.loading = !!value;
}

export function RESET_LOADING(state) {

  state.loading = initialState().loading;
}

export function SET_VALIDATED(state, value) {

  state.validated = !!value;
}

export function RESET_VALIDATED(state) {

  state.validated = initialState().validated;
}

export function SET_REGISTERED(state, value) {

  state.registered = !!value;
}

export function RESET_REGISTERED(state) {

  state.registered = initialState().registered;
}
