import initialState from './state';

export function SET_QUERY(state, value) {

  state.query = value || '';
}

export function RESET_QUERY(state) {

  state.query = initialState().query;
}
