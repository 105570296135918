
export default function () {

  return {
    email: '',
    name: '',
    surname: '',
    password: '',
    companyName: '',
    loading: false,
    validated: false,
    registered: false,
  };
}
