
export function breadcrumbs(state, getters, rootState) {

  const list = [
    {
      to: '/',
      label: 'Главная',
    },
  ];

  if (rootState.vlPages.item) {

    const item = rootState
      .vlPages
      .item;

    list.push({
      to: `/page/${item.slug}`,
      label: item.title,
    });
  }

  if (rootState.vlEvents.item) {

    const item = rootState
      .vlEvents
      .item;

    list.push({
      to: '/events',
      label: 'Мероприятия',
    });

    list.push({
      to: `/event/${item.slug}`,
      label: item.title,
    });
  }

  if (rootState.vlNews.item) {

    const item = rootState
      .vlNews
      .item;

    list.push({
      to: '/news',
      label: 'Новости',
    });

    list.push({
      to: `/news-item/${item.slug}`,
      label: item.title,
    });
  }

  return list;
}
