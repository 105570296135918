import createState from './state';
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';

export default function () {

  return {
    namespaced: true,
    getters,
    actions,
    mutations,
    state: createState(),
  };
}
