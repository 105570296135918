
const mixins = [];

export default function (factory) {

  return async (context) => {

    const {
      Vue,
      app,
      router,
      store,
    } = context;

    const boot = await factory(context);

    for (const name in boot) {

      if (!boot.hasOwnProperty(name))
        continue;

      const key = `$${name}`;

      // Create mixin.

      if (mixins.indexOf(name) === -1) {

        mixins.push(name);

        Vue.mixin({
          beforeCreate() {

            const options = this.$options;

            if (options[name]) {

              this[key] = options[name];

            } else if (options.parent) {

              this[key] = options.parent[key];
            }
          },
        });
      }

      // Inject.

      app[name] = boot[name];
      store[key] = boot[name];
      router[key] = boot[name];
    }
  };
}
