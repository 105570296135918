
export function PUT_NAV_ITEM({state, commit}, item) {

  let found = false;

  let items = state.tempItem.items.map(value => {

    if (value.id !== item.id)
      return value;

    found = true;
    return item;
  });

  if (!found)
    items = [item, ...items];

  commit('SET_TEMP_ITEM', {
    ...state.tempItem,
    items,
  });
}

export function REMOVE_NAV_ITEM({state, commit}, item) {

  const items = state.tempItem.items.filter(
    value => value.id !== item.id,
  );

  commit('SET_TEMP_ITEM', {
    ...state.tempItem,
    items,
  });
}
