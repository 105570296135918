/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import '@quasar/extras/material-icons/material-icons.css'



import '@quasar/extras/animate/pulse.css'

import '@quasar/extras/animate/fadeIn.css'

import '@quasar/extras/animate/fadeOut.css'

import '@quasar/extras/animate/zoomIn.css'

import '@quasar/extras/animate/zoomOut.css'

import '@quasar/extras/animate/slideInUp.css'

import '@quasar/extras/animate/slideOutUp.css'

import '@quasar/extras/animate/slideInDown.css'

import '@quasar/extras/animate/slideOutDown.css'


// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'


// We add Quasar addons, if they were requested
import 'quasar/src/css/flex-addon.sass'



import 'src/css/app.scss'

import 'src/css/bc.overrides.scss'

import 'src/css/block-spacing.scss'

import 'src/css/plyr.overrides.scss'

import 'src/css/block-max-width.scss'

import 'src/css/swiper.overrides.scss'

import 'src/css/froala.overrides.scss'

import 'src/css/quasar.overrides.scss'

import 'src/css/vendor/line-icons.css'

import 'src/css/vendor/gotham-pro.css'

import 'typeface-rubik/index.css'

import 'cropperjs/dist/cropper.css'


import Vue from 'vue'
import createApp from './app.js'




import qboot_Bootapi from 'boot/api'

import qboot_Bootplyr from 'boot/plyr'

import qboot_Booti18n from 'boot/i18n'

import qboot_Bootdate from 'boot/date'

import qboot_Bootutils from 'boot/utils'

import qboot_Bootaxios from 'boot/axios'

import qboot_Bootmixins from 'boot/mixins'

import qboot_Bootmoment from 'boot/moment'

import qboot_Bootswiper from 'boot/swiper'

import qboot_Bootqdate from 'boot/q-date'

import qboot_Bootcookies from 'boot/cookies'

import qboot_Bootfilters from 'boot/filters'

import qboot_Bootvalidator from 'boot/validator'

import qboot_Bootextensions from 'boot/extensions'

import qboot_Bootcomponents from 'boot/components'

import qboot_Bootvue2filters from 'boot/vue2-filters'

import qboot_Bootrouterguard from 'boot/router-guard'

import qboot_Bootstatecleaner from 'boot/state-cleaner'

import qboot_Bootquasarlanguage from 'boot/quasar-language'

import qboot_Bootfroala from 'boot/froala'

import qboot_Bootscrollto from 'boot/scroll-to'

import qboot_Bootsocketio from 'boot/socket-io'

import qboot_Bootvueclickoutside from 'boot/vue-click-outside'

import qboot_Bootvuebeautifulchat from 'boot/vue-beautiful-chat'



import { addPreFetchHooks } from './client-prefetch.js'










const publicPath = `/`


async function start () {
  const { app, store, router } = await createApp()

  

  
  let hasRedirected = false
  const redirect = url => {
    hasRedirected = true
    const normalized = Object(url) === url
      ? router.resolve(url).route.fullPath
      : url

    window.location.href = normalized
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootapi,qboot_Bootplyr,qboot_Booti18n,qboot_Bootdate,qboot_Bootutils,qboot_Bootaxios,qboot_Bootmixins,qboot_Bootmoment,qboot_Bootswiper,qboot_Bootqdate,qboot_Bootcookies,qboot_Bootfilters,qboot_Bootvalidator,qboot_Bootextensions,qboot_Bootcomponents,qboot_Bootvue2filters,qboot_Bootrouterguard,qboot_Bootstatecleaner,qboot_Bootquasarlanguage,qboot_Bootfroala,qboot_Bootscrollto,qboot_Bootsocketio,qboot_Bootvueclickoutside,qboot_Bootvuebeautifulchat]

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  

    
    addPreFetchHooks(router, store)
    

    

    
      new Vue(app)
    

    

    

  

}

start()
