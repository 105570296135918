
export function randStr({
  length = 5,
  noDigits = false,
}) {

  let result = '';
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  if (!noDigits)
    chars += '0123456789';

  const len = chars.length;

  for (let i = 0; i < length; i++) {

    result += chars.charAt(
      Math.floor(Math.random() * len),
    );
  }

  return result;
}

export function stripTags(string) {

  if (typeof string !== 'string')
    return string;

  if (typeof document !== 'undefined') {

    const div = document.createElement('div');
    div.innerHTML = string;
    return div.textContent || div.innerText || '';
  }

  return string.replace(/<(?:.|\n)*?>/gm, '') || '';
}

export function htmlHasContent(string) {

  const hasText = !!stripTags(string);
  const hasImages = /<img/.test(string);
  const hasIframe = /<iframe/.test(string);
  return hasText || hasImages || hasIframe;
}

/**
 * N to br tag.
 *
 * @param string
 * @return {string}
 */
export function nToBr(string) {

  return (string || '').replace(/\n/g, '<br/>');
}
