import {date} from 'quasar';

export function studios(state) {

  return state.runningIntro
    && state.runningIntro.studios
    || [];
}

export function getRunning(
  state,
  getters,
  rootState,
  rootGetters,
) {

  return () => {

    const userId = rootGetters['session/userId'];
    const guideId = rootGetters['session/guideId'];

    return state.items.filter(({guideIds, memberIds}) => {

      const iGuide = guideIds.indexOf(userId) !== -1;
      const myGuide = guideIds.indexOf(guideId) !== -1;
      const iMember = memberIds.indexOf(userId) !== -1;

      return iGuide
        || myGuide
        || iMember;
    });
  };
}

export function getRunningIntros(state, getters) {

  return () => getters.getRunning().filter(
    program => date.nowBetween(
      program.startDate,
      program.endDate,
      {offset: -3}, // Moscow Offset.
    ),
  );
}

export function getRunningPlayers(state, getters) {

  return () => getters.getRunning().filter(
    program => date.nowBetween(
      program.startPlayer,
      program.endPlayer,
      {offset: -3}, // Moscow Offset.
    ),
  );
}
