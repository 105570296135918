import initialState from './state';

export function SET_ITEMS(state, value) {

  state.items = value || [];
}

export function RESET_ITEMS(state) {

  state.items = initialState().items;
}
