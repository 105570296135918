
/**
 * Format
 *
 * Example usage:
 * String.format('{0} is dead, but {1} is alive! {0} {2}', 'ASP', 'ASP.NET')
 * will return: "ASP is dead, but ASP.NET is alive! ASP {2}"
 *
 * @param pattern
 */
String.format = function (pattern) {

  // eslint-disable-next-line prefer-rest-params
  const args = Array.prototype.slice.call(arguments, 1);
  const regEx = /{(\d+)}/g;

  const replacer = function (match, number) {

    return typeof args[number] !== 'undefined'
      ? args[number]
      : match;
  };

  return pattern.replace(
    regEx,
    replacer,
  );
};
