
export default function () {

  return {
    // item: null,
    // tempItem: null,
    // items: [],
    // skip: 0,
    limit: 99,
    // total: 0,
    orderBy: 'startDate',
    // orderDesc: false,
    // searchBy: ['name'],
    // searchQuery: '',
    // where: {},
    // loading: false,
    include: [
      {'studios': 'channels'},
    ],
    // fields: [],
    runningIntro: null,
    runningPlayer: null,
    timer: null,
  };
}
