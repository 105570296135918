
/**
 * Localize.
 *
 * @param object
 * @param locale
 * @return {string|*}
 */
export function localize(object, locale = 'ru') {

  if (object === 0)
    return object + '';

  if (!object && object !== 0)
    return '';

  if (typeof object === 'string')
    return object;

  if (locale in object)
    return object[locale];

  return '';
}
