import {Notify} from 'quasar';

export function SEARCH({state, commit, dispatch}, payload) {

  if (payload !== undefined)
    commit('SET_QUERY', payload);

  if (state.query.length < 3)
    return Notify.create({
      message: 'Не менее 3х символов',
      color: 'warning',
    });

  const route = this.$router
    .currentRoute
    .name;

  if (route !== 'public-search')
    return this.$router.push(
      `/search?q=${state.query}`,
    );

  dispatch(
    'vlNews/SEARCH_ITEMS',
    {query: state.query},
    {root: true},
  );

  dispatch(
    'vlEvents/SEARCH_ITEMS',
    {query: state.query},
    {root: true},
  );

  return state;
}
