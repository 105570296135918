import {Notify} from 'quasar';
import {onError} from '@src/store';
import {messageFromError} from '@src/utils';

export async function SUBMIT({state, commit}, email) {

  if (!email)
    return onError({
      message: 'E-mail required.',
    });

  commit('SET_LOADING', true);

  let response;

  try {

    response = await this.$api.post(
      'subscriptions/submit',
      {email},
    );

  } catch (error) {

    const message = messageFromError(
      error,
      'Неизвестная ошибка',
    );

    if (message.indexOf('unique') > -1) {

      response = true;

    } else {

      Notify.create({
        message,
        color: 'negative',
      });
    }

  }

  if (response)
    Notify.create({
      message: 'Благодарим за подписку!',
      color: 'positive',
    });

  commit('RESET_LOADING');

  return !!response;
}
